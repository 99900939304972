@media only screen and (max-width: 1000px) {
  .demoblock-disc {
    font-size: 18px !important;
  }

  .demoblock-subheader {
    margin-top: 80px;
  }

  .demostep-container {
    display: flex !important;
    flex-direction: column;
  }

  .demostep {
    margin-top: 40px;
  }

  .demostep:nth-child(1) > img {
    position: relative;
    transform: translateX(20px);
  }

  .demostep:nth-child(2) > img {
    position: relative;
    transform: translateX(10px);
  }

  .demostep:nth-child(3) > img {
    position: relative;
    transform: translateX(10px);
  }
}

.demoblock-container {
  text-align: center;
  position: relative;
}
.demoblock-header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #133a4b;
}

.demoblock-subheader {
  color: #133a4b;
}

.demoblock-disc {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  margin-bottom: 50px;
}

.demostep-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.demostep-disc {
  padding: 0 60px;
}

#demostep-mouse {
  position: relative;
}
#mouse-icon {
  position: absolute;
  left: 37%;
  top: -35px;
}
#demostep-header-mouse {
  margin-top: 127.3px;
}

.icontrib-phone {
  z-index: -2;
}
.icontrib-gif {
  position: absolute;
  left: 523px;
  top: 542px;
  width: 96px;
  height: 96px;
  z-index: 2;
}
.icontrib-filler {
  position: absolute;
  left: 478px;
  bottom: 600px;
  z-index: 1;
}
