.StripedTip {
  display: flex;
  align-items: center;
  margin: 5px;
  width: 300px;
}

.StripedTip-Image {
  margin-right: 10px;
}

.StripedTip-Text {
  /* width: 200px; */
  font-family: Open Sans;
  font-weight: 400;
  /* font-size: 18px; */
}
