.TeamBlock {
  margin-bottom: 100px;
  margin-top: 200px;
}

.TeamBlock-Header {
  text-align: center;
}

.TeamBlock-Header h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  color: #133a4b;
}

.TeamBlock-Gallery {
  text-align: center;
}

.TeamBlock-TeamName {
  font-size: 36px;
  color: #133a4b;
  margin-top: 100px;
}

/* REMOVE OUTLINE OF LIBRARY CLASSES */

.slick:focus,
.slick-active:focus,
.slick-cloned:focus {
  outline: 0 !important;
}
