.emergency-banner {
  display: flex;
  background-color: #ffc3b7;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 20px;
}

.emergency-banner > div {
  width: 100%;
  text-align: center;
  height: auto;
}

.emergency-banner > div > a {
  text-transform: none;
  padding: 0;
  margin: 0;
  color: #0645ad;
  font-size: 18px;
  font-weight: 700;
}
