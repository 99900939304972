* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

a.nav-bar-logo {
  text-transform: none !important;
  padding: 20px !important;
  box-sizing: border-box;
}

a.navbar-logo {
  text-transform: none !important;
  padding: 0px !important;
  box-sizing: border-box;
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  height: 100px;
  margin-bottom: 50px;
  --size-of-push: 350px;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  width: 60%;
}

.nav-link {
  padding: 20px 10px;
  font-size: 20px;
  font-size: 22px;
  font-weight: normal;
  color: #736b6b;
  height: 60px;

  background-image: linear-gradient(#ffbc03, #ffbc03),
    linear-gradient(rgb(255, 255, 255, 0), rgb(255, 255, 255, 0));
  background-size: 0% 4px, auto;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  transition: all 0.2s ease-out;
}

.nav-link:hover,
.nav-link.selected {
  background-size: 100% 4px, auto;
}

.nav-links a {
  text-decoration: none;
}

.nav-links li {
  list-style: none;
  color: #000;
}

.burger {
  display: none;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 5px;
  transition: all 0.2s;
}

.nav-links li:nth-child(1) {
  display: none;
}

@media screen and (max-width: 700px) {
  html,
  body {
    overflow-x: hidden;
  }
  .site-margin {
    padding-top: 140px !important;
  }
  nav {
    width: 100%;
    position: fixed;
    top: 0px;
    background-color: #fff;
    z-index: 1000;
    --size-of-push: 100%;
  }
  .nav-links li:nth-child(1) {
    display: block;
  }
  .nav-links {
    position: absolute;
    background-color: #fff;
    right: 0vh;
    height: calc(100vh - 100px);
    top: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: var(--size-of-push);
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
  }
  .nav-links li {
    opacity: 0;
  }
  .burger {
    display: block;
  }
  .scroll-down {
    transform: translateY(-100px);
    transition: 0.2s;
  }

  .scroll-up {
    transform: translateY(0px);
    transition: 0.2s;
  }
  a.nav-bar-logo {
    padding-left: 0 !important;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(500px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media screen and (max-width: 1000px) {
  html,
  body {
    overflow-x: hidden;
  }
  .site-margin {
    padding-top: 140px !important;
  }
  nav {
    width: 100%;
    position: fixed;
    background-color: #fff;
    z-index: 1000;
  }
  .nav-links li:nth-child(1) {
    display: block;
  }
  .nav-links {
    position: absolute;
    background-color: #fff;
    right: 0vh;
    height: calc(100vh - 100px);
    top: 100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: var(--size-of-push);
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
  }
  .nav-links li {
    opacity: 0;
    margin-bottom: 20px;
  }
  .burger {
    display: block;
  }
  .scroll-down {
    transform: translateY(-100px);
    transition: 0.2s;
  }

  .scroll-up {
    transform: translateY(0px);
    transition: 0.2s;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(500px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes siteMarginPush {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateX(calc(var(--size-of-push) * -1));
    opacity: 0.2;
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
