.NavBarLogo {
  padding: 0 !important;
  text-transform: none !important;
  margin-left: 10px;
}

.NavBarLogo-Company {
  margin-bottom: 0;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 400;
  color: #026896;
}

.NavBarLogo-Slogan {
  margin-top: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  color: #736b6b;
}

.NavBarLogoMobile {
  text-transform: none !important;
  width: 200px;
  margin: 0px auto;
}

.nav-bar-logo-m {
  padding: 0 !important;
  width: 100%;
}
