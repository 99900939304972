.VideoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.VideoBlock-header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #133a4b;
}

.VideoBlock-embed {
  width: 75%;
  max-width: 300px;
  height: 150px;
}
