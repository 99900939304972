.organization-page .main-container {
  display: grid;
  grid-template-columns: 500px 700px;
  margin-bottom: 200px;
}
.organization-page .main-info {
  text-align: right;
}

.organization-page .main-info h1,
.organization-page .main-info p {
  width: 500px;
}

.organization-photoblock .photo-block-box {
  margin-left: 60px;
}
.organization-photoblock .photo-block {
  width: 628px;
  height: 512px;
  padding: 0;
  position: relative;
  box-shadow: 0px 4px 30px rgba(233, 233, 233, 0.51);
}

.organization-photoblock .photo-block .photo-infoblock {
  margin: 0 40px;
  margin-bottom: 30px;
  width: 548px;
  position: absolute;
  bottom: 0;
}

.organization-photoblock .photo-block .photo-distance {
  margin-top: 0;
}
.organization-photoblock .photo-block .photo-block-photo {
  margin-top: 40px;
}
.organization-photoblock .photo-block h1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #026896;
}
.organization-photoblock .photo-block h2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}
.organization-photoblock .photo-block h3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}
