.EventCardCarousel {
  margin-top: 30px;
}

.CarouselItem {
  user-select: none;
}

.CarouselItem > img {
  max-width: 500px;
}
