.ProfileModal {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 30%;
  left: 30%;
  right: 30%;
  bottom: 30%;
  background-color: #fff;
  /* box-shadow: 0px 4px 30px rgba(233, 233, 233, 0.51); */
  border: solid #f1f1f2 1px;
  border-radius: 50px;
}

.ProfileModal:focus {
  outline: 0;
}

.ProfileModal-Info {
  text-align: center;
  margin: 50px;
  min-width: 200px;
}

.ProfileModal-Pic {
  width: 150px;
  height: 150px;
  border-radius: 75px;
}

.ProfileModal-Name {
  font-size: 24px;
  margin-bottom: 0;
}

.ProfileModal-Role,
.ProfileModal-DateJoined,
.ProfileModal-Status {
  font-size: 14px;
}

.ProfileModal-Role {
  margin-top: 0;
}

.ProfileModal-DateJoined {
  margin-bottom: 0;
  /* text-align: left; */
}

.ProfileModal-Status {
  margin-top: 0;
  /* text-align: left; */
}

.ProfileModal-Narrative {
  font-size: 14px;
  margin-right: 50px;
  font-size: 14px;
}

/* ANIMATIONS: */

.ReactModal__Overlay {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  transition: opacity 200ms;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
