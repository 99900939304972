.ProfileCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProfileCard:focus {
  outline: 0;
}

.ProfileCard-Pic {
  cursor: pointer;

  width: 300px;
  height: 300px;
  border-radius: 150px;
}

.ProfileCard-Name {
  font-size: 24px;
  margin-bottom: 0;
}

.ProfileCard-Role {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 5px;
}

.ProfileCard-Alumni {
  font-size: 14px;
  margin-top: 0;
}
